/********** MENU **********/


.menu {
	width: 13em;
}

footer {
	background-color: var(--theme-primary);
	border-radius: 16px;
	padding: 16px;
	margin-top: 10vh;
	.cc{
		color: white;
		font-size: 16px;
		.cc-link {
			color: gold;

			&:visited {
				color: rgb(174, 234, 252);
			}
		}
	}
}