/********** GAME SETTINGS **********/

.settings {
	width: 17em;
	text-transform: none;
}
.settings h2 {
	width: 40%;
	margin: 0;
	padding: 0;
	float: left;
	font-weight: 400;
	color: #000;
	text-align: right;
}
.settings .radio {
	width: 55%;
	float: right;
}
.settings .radio label {
	color: #000;
	text-transform: uppercase;
	cursor: pointer;
}
.settings .radio label:hover {
	color: #fff;
	text-shadow: 0 0 2px #fff, 0 0 4px #fff;
}
.settings .radio input[type="radio"] + label:before {
	content: '';
	display: block;
	float: left;
	width: .5em;
	height: .5em;
	margin: .2em .2em 0 0;
	border-radius: 50%;
	border: 1px inset var(--theme-primary);
	background-color: var(--theme-primary);
}
.settings .radio input[type="radio"]:checked + label {
	color: #fff;
}
.settings .radio input[type="radio"]:checked + label:before {
	background-color: #fff;
}
.settings .radio input {
	display: none;
}