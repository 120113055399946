@charset 'utf-8';
@use 'css/icomoon.css';

@import url(//fonts.googleapis.com/css?family=Roboto+Condensed:400,700);



html, body {
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 32px;
	font-weight: 400;
	// --theme-light: rgb(102, 165, 238);
	// --theme-dark: rgb(51, 75, 153);
	// --theme-primary: rgb(62, 89, 179);
	// --link-color: #fff;
	// --primary-text-color: #fff;
	// --primary-text-highlight: #000;
	// --option-background: hsla(0,0%,100%,.35);
	background-color: var(--theme-light);
	color: var(--primary-text-color);
	line-height: 1.1;
}

/********** GENERAL FORMATTING **********/

h1, h2, h3, h4, h5, h6 {
	margin: 10px 0;
	font-weight: 700;
}
h1 {
	font-size: 1.4em;
	color: var(--primary-text-color);
}
h2 {
	font-size: 1em;
	font-weight: 400;
	color: var(--primary-text-color);
}
p {
	margin: 10px 0;
}
a {
	text-decoration: none;
	color: var(--link-color);

	&:hover,
	&:focus {
		color: var(--primary-text-color);;
		text-shadow: 0 0 2px var(--primary-text-color), 0 0 4px var(--primary-text-color);;
	}

	&.active {
		color: var(--primary-text-color);;
	}
}

button.Link {
	text-decoration: none;
    color: var(--link-color);
	cursor: pointer;

	&:hover, 
	&:focus {
		color: var(--primary-text-color);
		text-shadow: 0 0 2px var(--primary-text-color), 0 0 4px var(--primary-text-color);
	}

	&.disabled, 
	&.disabled:hover {
		color: rgb(46, 57, 71);
		cursor: default;
		text-shadow: none;
	}
}
	// Inherit away default button styling

button.button {
	color: inherit;
	cursor: default;
	text-shadow: none;
}

button.button,
button.Link {
	appearance: inherit;
    font-style: inherit;
    font-weight: inherit;
    font-stretch: inherit;
    font-size: inherit;
    font-family: inherit;
    text-rendering: inherit;
    letter-spacing: inherit;
    word-spacing: inherit;
    line-height: inherit;
    text-transform: inherit;
    text-indent: 0px;
    display: inline;
    text-align: inherit;
    align-items: flex-start;
    box-sizing: border-box;
    background-color: inherit;
    margin: inherit;
    padding: inherit;
    border-width: inherit;
    border-style: inherit;
    border-color: inherit;
    border-image: inherit;
}


button.button,
a.button {
	padding: 5px 30px;
	background-color: var(--theme-dark);
	background-color: rgba(0, 0, 0, .25);
	color: var(--primary-text-color);
	text-transform: uppercase;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	-o-border-radius: 50px;
	border-radius: 50px;

	&:hover,
	&:focus {
		background-color: #fff;
		color: var(--theme-primary);
		text-shadow: none;
	}
	
	&--menu {
		background-color: var(--theme-primary);
	
		&:hover,
		&:focus {
			background-color: #fff;
			color: var(--theme-primary);
			text-shadow: none;
		}
	}
}


.nobreak {
	white-space: nowrap;
}

/********** CONTAINERS **********/

.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 30px;
	background-color: var(--theme-light);
	color: var(--link-color);;
	margin-top: 16px;
}

.panel { 
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	box-sizing: border-box;
	width: 450px;
	max-width: 95%;
	max-height: 95%;
	padding: 30px 40px;
	box-sizing: border-box;
	text-transform: uppercase;
	background-color: var(--theme-primary);
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	-o-border-radius: 30px;
	border-radius: 30px;
	
	footer {
		margin-top: 10px;
	}


	.main {
		flex-grow: 1;
		overflow-y: auto;
		width: 100%;
		padding: .5em 0;
	}
	
	footer a {
		display: inline-block;
		margin: .2em;
		white-space: nowrap;
	}

	h1, 
	h2 {
		text-transform: uppercase;
	}
}

.column {
	box-sizing: border-box;
	float: left;
	width: 50%;

	&.left {
		padding-right: 50px;
	}

	&.right {
		padding-left: 50px;
	}
}

.controls {
	list-style: none;
	margin: 0;
	padding: 0;

	li {
		margin: 0;
	}

	a.disabled, 
	a.disabled:hover {
		color: rgb(46, 57, 71);
		cursor: default;
		text-shadow: none;
	}
}


/********** MISC PANELS **********/
.info {
	width: 1100px;
	text-transform: none;
}

/********** MISC **********/

a.pdf {
	display: block;
	padding: 40px 0 40px 80px;
	// TODO find this image? 
  // background-image: url(../public/img/icon_pdf.png);
	background-size: 64px 64px;
	background-position: 0 25px;
	background-repeat: no-repeat;
}
.cf:after {
	display: block;
	content: '';
	clear: both;
}
.left {
	float: left;
}
.right {
	float: right;
}
.vertical_outer {
	display: table;
	width: 100%;
	height: 100%;
	vertical-align: middle;
}
.vertical_inner {
	display: table-cell;
	vertical-align: middle;
}


/********** MEDIA QUERIES **********/


@media (max-width: 1400px) {
	.option { 
		width: 45%; 
	}
}

@media (max-width: 720px), (max-height: 600px) {
	body {
		font-size: 26px;
	}
	h1 {
		font-size: 1.4em;
	}
	.question {
		height: 45%;
		font-size: 1.4em;
	}
	.option_box {
		padding: 0;
	}
	.option { 
		height: 100px;
		padding: .2em;
	}
	.option .vertical_inner {
		padding: .2em;
	}

}
@media (max-width: 500px) {
	.question {
		top: 8%;
		height: 35%;
		font-size: 1.2em;
	}
	.option {
		height: 100px;
		width: 90%;
	}
}
@media (max-width: 320px), (max-height: 370px) {
	
	body {
		font-size: 20px;
	}
	.container {
		padding: 10px;
	}
	.panel {
		padding: 10px 20px;
		-webkit-border-radius: 15px;
		-moz-border-radius: 15px;
		-o-border-radius: 15px;
		border-radius: 15px;
	}
	.panel header {
		margin-bottom: 0;
	}
	.panel footer {
		margin-top: 10px;
		margin-bottom: 10px;
	}
	.question {
		top: 8%;
		height: 35%;
	}
	
}

@media print {
	
	body {
		font-size: 14px;
	}
	.panel {
		width: 100% !important;
		height: auto !important;
		margin: 0 !important;
		padding: 0 !important;
		font-family: Arial, sans-serif !important;
		font-size: 1em  !important;
	}
	.summary {
		height: auto !important;
		color: #000 !important;
	}
	footer,
	.links {
		display: none;
	}
	
	h1, h2, h3, h4, h5, h6 {
		margin: 10px 0;
		font-family: Arial, sans-serif;
		font-weight: 400;
		color: #000;
	}
	h1 {
		font-size: 2em;
	}
	.controls {
		display: none;
	}
}

.content.cf ul.list-space li{
	margin-bottom: 16px;
}

.content.cf p{
	margin-bottom: 32px;
}
