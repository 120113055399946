/********** VIDEO **********/
@import url(//css/video-js.css);

iframe,
#vid-player{
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	margin-left: 50vw;
	transform: translate(-50%);
}

#pause{
	padding: 5px 30px;
	background-color: var(--theme-dark);
	background-color: rgba(0, 0, 0, .25);
	color: #fff;
	text-transform: uppercase;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	-o-border-radius: 50px;
	border-radius: 50px;
	font-size: 70px;

	pointer-events: none;
	
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	color: white;
	overflow: hidden;
	margin-left: 50vw;
	margin-top: 50vh;
	transform: translate(-50%)
}

.video, 
.videoplayer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
}
.left.controls,
.right.controls {
	position: absolute;
	top: 10px;
	right: 20px;
	padding: 10px;
	z-index: 13;
}
.left.controls {
	left: 20px;
	right: auto;
}