/********** INTRODUCTION **********/

.intro {
	width: 900px;
	background: none;
	text-transform: none;
}
.intro footer {
	text-align: center;
}

.intro-content {
	white-space: pre-line;
}